import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import aboutImg1 from "../../../images/about-1.jpg"
import aboutImg2 from "../../../images/about-2.jpg"
import aboutImg3 from "../../../images/about-3.jpg"
import "./about.scss"
import PlayVideo from "../../PlayVideo/PlayVideo"
import ScrollAnimation from "react-animate-on-scroll"
import AboutContent from "./AboutContent/AboutContent"
import { useWindowSize } from "../../../hooks/window-size"
import ReactMarkdown from "react-markdown"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const About = props => {
  const [isPlay, setIsPlay] = useState(false)
  const [windowWidth] = useWindowSize()

  const playVideoHandler = () => {
    setIsPlay(true)
  }

  const videoContent = props.Image ? (
    <motion.div className="video-wrapper" variants={fadeInFromRight}>
      <picture>
        <source media="(min-width: 768px)" srcSet={props.Image?.url} />
        <source media="(min-width: 1400px)" srcSet={props.Image?.url} />
        <img src={props.Image?.url} alt={"About DNG - DNG Estate Agents"} />
      </picture>
      {props.Video_Url && (
        <div className="btn-wrap">
          <button onClick={playVideoHandler}>
            <i className="icon-play"></i>
          </button>
        </div>
      )}
    </motion.div>
  ) : null

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="about"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
          style={{
            backgroundColor:
              props.Background_Color === "White"
                ? "#ffffff"
                : props.Background_Color === "Grey"
                ? "#F7F8FA"
                : "#ffffff",
          }}
        >
          <Container>
            <Row>
              <Col sm="12">
                {props.PageName === "Home" &&
                  windowWidth < 1200 &&
                  videoContent}
                {props?.Title && (
                  <motion.div variants={fadeInFromLeft}>
                    <ReactMarkdown source={props?.Title} allowDangerousHtml />
                  </motion.div>
                )}
                {/* {props?.Content && <ReactMarkdown source={props?.Content} allowDangerousHtml />} */}
                {/* {props.Tails && */}
                <div className="bottom-block">
                  {/* {JSON.stringify(props.Content)} */}
                  {props.Align_type === "Right" ? (
                    <>
                      <AboutContent
                        Choose_Team={props.Choose_Team}
                        Content={props.Content}
                        Tail_Type={props.Tail_Type}
                        Tails={props.Tails}
                      />
                      <>{windowWidth > 1199 && videoContent}</>
                    </>
                  ) : props.Align_type === "Left" ? (
                    <>
                      <>{windowWidth > 1199 && videoContent}</>
                      <AboutContent
                        Choose_Team={props.Choose_Team}
                        Content={props.Content}
                        Tail_Type={props.Tail_Type}
                        Tails={props.Tails}
                      />
                    </>
                  ) : (
                    <>
                      <AboutContent
                        Choose_Team={props.Choose_Team}
                        Content={props.Content}
                        Tail_Type={props.Tail_Type}
                        Tails={props.Tails}
                      />
                      <>{windowWidth > 1199 && videoContent}</>
                    </>
                  )}
                </div>

                {/* } */}
                {props.PageName !== "Home" &&
                  windowWidth < 1200 &&
                  videoContent}
              </Col>
            </Row>
          </Container>
          <PlayVideo
            isOpen={isPlay}
            videoId={props.Video_Url}
            stopPlay={setIsPlay}
          />
        </motion.section>
      )}
    </InView>
  )
}

export default About
