import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import { Office_Page_Url } from "../../common/site/constants"

import NoImage from "../../../images/no-image.png"
import CloseIcon from "../../../images/close-icon.png"

import { useWindowSize } from "../../../hooks/window-size"
import $ from "jquery"

const OfficeItem = ({ item, hideOfficeDet, showOffcDet, handleShow }) => {
  const [windowWidth] = useWindowSize()

  //   useEffect(() => {
  //     var get_div_pos = $(".office-List-block").offset()

  //     window.scrollTo(0, get_div_pos.top)
  //   }, [showOffcDet])

  const ImageRender = ({ item }) => {
    let processedImages = JSON.stringify({})
    if (item?.imagetransforms?.Thumbnail_Transforms) {
      processedImages = item.imagetransforms.Thumbnail_Transforms
    } else {
      processedImages = item.imagetransforms.Banner_Transforms
    }

    var tail_image = item.Thumbnail ? item.Thumbnail : item.Banner_Image

    return (
      <ImageTransform
        imagesources={tail_image?.url}
        renderer="srcSet"
        imagename="office.Thumbnail.tail"
        attr={{
          alt: `${item.Office_Name} ${item.Branch_Location} - DNG Estate Agents`,
          class: "",
        }}
        imagetransformresult={processedImages}
        id={item.id}
      />
    )
  }

  //console.log("officeDetails", officeDetails, showOffcPopup)

  const customClass = item.Map_Pointer_Top_Position_Percentage
  let arrowName = ""
  if (customClass < 50) {
    arrowName = "top_arrow"
  } else {
    arrowName = ""
  }

  return (
    <div
      className="office-block"
      onMouseOver={e => {
        showOffcDet(item)
      }}
    >
      {windowWidth > 767 ? (
        <span className={`down_arw ${arrowName}`}></span>
      ) : (
        <span
          className="down_arw"
          style={{ left: item.Map_Pointer_Left_Position_Percentage + "%" }}
        ></span>
      )}

      <a href="javascript:;" className="close_icon">
        <img src={CloseIcon} alt={"Close"} onClick={hideOfficeDet} />
      </a>

      <div className="person-img">
        <Link
          to={`${Office_Page_Url.alias}/${item.URL}/`}
          className="img-zoom img-wrap"
        >
          {item.Thumbnail || item.Banner_Image ? (
            <ImageRender item={item} />
          ) : (
            <img
              src={NoImage}
              alt={`${item.Office_Name} ${item.Branch_Location} - DNG Estate Agents`}
            />
          )}
        </Link>
      </div>
      <div className="person-info">
        <span className="person-name">
          <Link to={`${Office_Page_Url.alias}/${item.URL}/`}>
            {item.Office_Name}, {item.County}
          </Link>
        </span>
        <span className="branch-name d-none d-xl-block">
          <ReactMarkdown
            source={item.Address?.replace(/,/g, ",<br/>")}
            allowDangerousHtml
          />
        </span>
        {item.Email && (
          <a
            href="javascript:;"
            onClick={() => handleShow(item)}
            className="email-text d-block d-xl-none"
          >
            {item.Email}
          </a>
        )}
        <div className="person-contact">
          {item.Contact_No && (
            <Link href={"tel:" + item.Contact_No} className="phone-number">
              <i className="icon-phone"></i> {item.Contact_No}
            </Link>
          )}
          {item.Email && (
            <a
              href="javascript:;"
              onClick={() => handleShow(item)}
              className="mail-text d-none d-xl-flex"
            >
              <i className="icon-mail"></i>
            </a>
          )}

          <Link
            to={`${Office_Page_Url.alias}/${item.URL}/`}
            className="arrow-symbol"
          >
            details<i className="icon-arrow"></i>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default OfficeItem
