import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"
//import mapImage from '../../../images/data.svg';
//import mapImage from '../../../images/branch_map.svg';
import mapImage from "../../../images/branch_map_without_pointer.svg"

import ReactMarkdown from "react-markdown"
import Modal from "react-bootstrap/Modal"

import "./officeLocation.scss"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import OfficeForm from "@Components/forms/officecontact"
import { OfficeList } from "../../../queries/common_use_query"

import { useWindowSize } from "../../../hooks/window-size"
import OfficeItem from "./OfficeItem"

import $ from "jquery"

const OfficeLocation = props => {
  const [leftPosition, setLeftPosition] = useState(0)
  const [topPosition, setTopPosition] = useState(0)
  const [leftPositionCss, setLeftPositionCss] = useState(0)
  const [topPositionCss, setTopPositionCss] = useState(0)
  const [officeDetails, setOfficeDetails] = useState("")
  const [showOffcPopup, setShowOfficePopup] = useState(false)
  const [show, setShow] = useState(false)

  const [windowWidth] = useWindowSize()

  const handleClose = () => setShow(false)
  const handleShow = item => {
    setShow(true)
    setOfficeDetails(item)
  }

  const { loading, error, data } = OfficeList()

  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s?.charAt(0).toUpperCase() + s?.slice(1)
  }

  const showOffcDet = item => {
    //console.log("officeDetails show", officeDetails)
    setShowOfficePopup(true)
    // !showOffcPopup ? setOfficeDetails(item) : setShowOfficePopup(false)

    item?.id !== officeDetails?.id && setOfficeDetails(item)
  }



  const hideOfficeDet = () => {
    //console.log("officeDetails hide", officeDetails)
    setOfficeDetails("")
    return false
  }

  const getBranchPosition = e => {
    var rect = e.target.getBoundingClientRect()
    var left_position = e.clientX - rect.left //x position within the element.
    var top_position = e.clientY - rect.top //y position within the element.

    var percentLeft = (left_position / e.target.offsetWidth) * 100
    var percentTop = (top_position / e.target.offsetHeight) * 100

    setLeftPosition(percentLeft.toFixed(1).replace(".0", ""))
    setTopPosition(percentTop.toFixed(1).replace(".0", ""))
    setLeftPositionCss(left_position)
    setTopPositionCss(percentTop + 2)

    $(".pos_tooltip").addClass("show")

    //   setTimeout(() => {
    // 	$(".pos_tooltip").removeClass("show");
    //   }, 5000)

    //console.log("Left offset : ", e.target.offsetWidth, e.target.offsetHeight);

    // console.log("Left : ", e.target.parentNode, percentLeft, percentTop, left_position, top_position);
  }

  //console.log("officeDetails", officeDetails, showOffcPopup)

  const areaList = props?.areas?.map(area => area.node)
  const sortedArea = areaList?.sort((x, y) =>
    x.AreaName.localeCompare(y.AreaName)
  )

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="office-location"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col sm="12">
                <div className="content-wrapper">
                  <motion.div
                    className="location-map"
                    variants={fadeInFromRight}
                  >
                    <img src={mapImage} alt="Map" />

                    {data &&
                      data?.offices?.length > 0 &&
                      data?.offices
                        ?.filter(
                          item =>
                            item.Map_Pointer_Left_Position_Percentage &&
                            item.Map_Pointer_Top_Position_Percentage
                        )
                        .map((item, index) => {
                          return (
                            <span
                              className={`pointer ${
                                item.id === officeDetails?.id ? "active" : ""
                              }`}
                              style={{
                                left:
                                  item.Map_Pointer_Left_Position_Percentage +
                                  "%",
                                top:
                                  item.Map_Pointer_Top_Position_Percentage +
                                  "%",
                              }}
                              onClick={e => {
                                showOffcDet(item)
                              }}
                                onMouseLeave={() => {
                                  // hideOfficeDet()
                                  // setShowOfficePopup(false)
                                }}
                              onMouseOver={e => {
                                showOffcDet(item)
                              }}
                              // onMouseOut={e=>{
                              //   closeOfficeDetails(item)
                              // }}
                            >
                              {item.id === officeDetails?.id &&
                                (windowWidth > 767 ? (
                                  <div
                                    className={`office-List-block popup ${
                                      item.Map_Pointer_Left_Position_Percentage >
                                        65 &&
                                      item.Map_Pointer_Top_Position_Percentage >
                                        50
                                        ? "right-top-position"
                                        : item.Map_Pointer_Left_Position_Percentage >
                                          65
                                        ? "right_pos"
                                        : item.Map_Pointer_Top_Position_Percentage >
                                          50
                                        ? "top-position"
                                        : ""
                                    } mt-0 mb-0`}
                                  >
                                    <OfficeItem
                                      item={item}
                                      showOffcDet={showOffcDet}
                                      hideOfficeDet={hideOfficeDet}
                                      handleShow={handleShow}

                                    />
                                  </div>
                                ) : (
                                  <div
                                    className={`office-List-block popup mt-0 mb-0`}
                                    style={{
                                      display: "block",
                                      left:
                                        item.Map_Pointer_Left_Position_Percentage -
                                        100 +
                                        "%",
                                      transform:
                                        "translate(-" +
                                        item.Map_Pointer_Left_Position_Percentage +
                                        "%, -100%)",
                                    }}
                                  >
                                    <OfficeItem
                                      item={item}
                                      showOffcDet={showOffcDet}
                                      hideOfficeDet={hideOfficeDet}
                                      handleShow={handleShow}
                                    />
                                  </div>
                                ))}
                            </span>
                          )
                        })}

                    <Modal
                      show={show}
                      centered={true}
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                      dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                    >
                      <Modal.Header closeButton className="contact-close-btn">
                        <Modal.Title className="w-100">
                          Email to {officeDetails.Office_Name},{" "}
                          {officeDetails.County}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="form">
                        <OfficeForm
                          toemail={officeDetails.Email}
                          branchPhoneNum={officeDetails.Contact_No}
                          officeName={officeDetails.Office_Name}
                          branchLink={officeDetails.URL}
                          off_address={officeDetails.Address}
                        />
                      </Modal.Body>
                    </Modal>

                    {/* {
								<div className="pos_tooltip" style={{left:leftPositionCss - 272/2, top:(topPositionCss)+"%"}}>
									<b>Left Position:</b> {leftPosition}, <b>Top Position:</b> {topPosition}
								</div>
								} */}
                  </motion.div>
                  <div className="location-details">
                    {props?.Title && (
                      <motion.div variants={fadeInFromLeft}>
                        <ReactMarkdown
                          source={props?.Title}
                          allowDangerousHtml
                        />
                      </motion.div>
                    )}
                    {props?.Content && (
                      <motion.div variants={fadeInFromLeft}>
                        <ReactMarkdown
                          source={props?.Content}
                          allowDangerousHtml
                        />
                      </motion.div>
                    )}
                    <motion.div variants={contentItem} className="form-wrap">
                      <form
                        action="javascript:;"
                        onSubmit={props.seearchonSubmit}
                      >
                        <div className="form-content">
                          <div
                            className="input-wrap"
                            onMouseLeave={props.onleavesearch}
                          >
                            <label htmlFor="location">
                              Enter County or Region:
                            </label>
                            <div className="s-box">
                              <input
                                value={capitalize(props.searchvalue)}
                                onClick={props.seearchonClink}
                                onChange={props.seearchonChange}
                                type="text"
                                id="location"
                                className="form-control"
                                placeholder="e.g. Cork"
                                autoComplete={false}
                              />
                              {props.searchvalue && (
                                <Button
                                  onClick={props.seearchonClose}
                                  className="s-close"
                                >
                                  X
                                </Button>
                              )}
                            </div>
                            {props.showlist && (
                              <div className="showlocationlist">
                                <ul>
                                  {props.areas?.length > 0 ? (
                                    sortedArea?.map((node, index) => {
                                      return (
                                        <li
                                          onClick={() => {
                                            props.setsearchvalue(
                                              node.AreaName.toLowerCase()
                                            )
                                            props.setshowlist(false)
                                            sessionStorage.setItem(
                                              "searchvalue",
                                              node.AreaName.toLowerCase()
                                            )
                                          }}
                                        >
                                          {node.AreaName}
                                        </li>
                                      )
                                    })
                                  ) : (
                                    <li>No results Found</li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="btn-wrap">
                            <button type="submit" className="btn">
                              Search
                            </button>
                          </div>
                        </div>
                      </form>
                    </motion.div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </motion.section>
      )}
    </InView>
  )
}

export default OfficeLocation
