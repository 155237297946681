import { Link } from "gatsby"
import React, { Fragment, useState } from "react"
import opinionImage1 from "../../../../images/opinion-1.jpg"
import opinionImage2 from "../../../../images/opinion-2.jpg"
import opinionImage3 from "../../../../images/opinion-3.jpg"
import opinionImage4 from "../../../../images/opinion-4.jpg"
import PlayVideo from "../../../PlayVideo/PlayVideo"
import ScrollAnimation from "react-animate-on-scroll"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import ReactMarkdown from "react-markdown"
import { NEWS_PAGE_URL } from "@Components/common/site/constants"
import NoImage from "../../../../images/no-image.png"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const opinionData = [
  {
    id: 1,
    image: opinionImage1,
    videoId: "V8ksSGxo6no",
    name: "Residential",
    description: "Where would you buy your ideal starter home in South Dublin",
  },
  {
    id: 2,
    image: opinionImage2,
    videoId: "",
    name: "Auctions",
    description: "DNG Market Review South West Region 2019 & Outlook 2020",
  },
  {
    id: 3,
    image: opinionImage3,
    videoId: "",
    name: "Residential",
    description: "Where would your ideal starter home be in West Dublin",
  },
  {
    id: 4,
    image: opinionImage4,
    videoId: "V8ksSGxo6no",
    name: "DNG",
    description:
      "Introducing our new Head of Lettings Management Andrew Symonds",
  },
]

const OpinionList = props => {
  const [opinionList, setOpinionList] = useState(opinionData)
  const [isPlay, setIsPlay] = useState(false)
  const [video_url, setVideoURL] = useState(-1)

  const playVideoHandler = video_url => {
    setIsPlay(true)
    setVideoURL(video_url)
  }
  const ImageRender = ({ alt, image_url, node, post_item }) => {
    let processedImages = JSON.stringify({})
    if (post_item?.imagetransforms?.Tail_Image_Transforms) {
      processedImages = post_item.imagetransforms.Tail_Image_Transforms
    }

    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename="blog.Tail_Image.smalltail"
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }
  return (
    <Fragment>
      <ul className="opinion-list">
        {props.news.map((item, index) => {
          return (
            <motion.li
              key={index}
              custom={index}
              variants={fadeInFromRight}
              className="opinion-item"
            >
              <div className="left-block">
                <div className="img-zoom img-wrap">
                {item.External_Blog_Url?
                (
                  item?.External_Blog_Url.startsWith("https")?
                <a href={item.External_Blog_Url}>
                    {item?.Tail_Image?.url ? (
                      <ImageRender
                        alt={
                          item.Tail_Image?.alternativeText
                            ? item.Tail_Image?.alternativeText
                            : item.Title + " - DNG Estate Agents"
                        }
                        post_item={item}
                        image_url={item?.Tail_Image?.url}
                        node={item}
                      />
                    ) : (
                      <img
                        src={NoImage}
                        alt={item.Tail_Image?.alternativeText+" - DNG Estate Agents"}
                      />
                    )}
                  </a>
                  :
                  <Link to={item.External_Blog_Url}>
                    {item?.Tail_Image?.url ? (
                      <ImageRender
                        alt={
                          item.Tail_Image?.alternativeText
                            ? item.Tail_Image?.alternativeText
                            : item.Title + " - DNG Estate Agents"
                        }
                        post_item={item}
                        image_url={item?.Tail_Image?.url}
                        node={item}
                      />
                    ) : (
                      <img
                        src={NoImage}
                        alt={item.Tail_Image?.alternativeText+" - DNG Estate Agents"}
                      />
                    )}
                  </Link>
                )
                  :


                  <Link to={NEWS_PAGE_URL.alias + "/" + item.URL + "/"}>
                    {item?.Tail_Image?.url ? (
                      <ImageRender
                        alt={
                          item.Tail_Image?.alternativeText
                            ? item.Tail_Image?.alternativeText
                            : item.Title + " - DNG Estate Agents"
                        }
                        post_item={item}
                        image_url={item?.Tail_Image?.url}
                        node={item}
                      />
                    ) : (
                      <img
                        src={NoImage}
                        alt={item.Tail_Image?.alternativeText+" - DNG Estate Agents"}
                      />
                    )}
                  </Link>
                  }
                  {item.Video_Url && (
                    <div className="btn-wrapper">
                      <button type="button" onClick={() => playVideoHandler(item.Video_Url)}>
                        <i className="icon-play"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="right-block">
                <div className="name d-none d-md-block">
                  {item?.blog_categories?.map((list, index) => (
                    <>
                      {list.Category_Name}
                      {index === item?.blog_categories?.length - 1 ? "" : ", "}
                    </>
                  ))}
                </div>

                {item.External_Blog_Url?
                (
                  item?.External_Blog_Url.startsWith("https")?
                <a href={item.External_Blog_Url} className="detail">
                   {item.Title}
                </a> 
                :
                <Link to={item.External_Blog_Url} className="detail">
                   {item.Title}
                </Link>  
                )
                :
              
                <Link
                  to={NEWS_PAGE_URL.alias + "/" + item.URL + "/"}
                  className="detail"
                >
                  {item.Title}
                </Link>
                  }
              </div>
              <PlayVideo
                isOpen={isPlay}
                stopPlay={setIsPlay}
                videoId={video_url}
                isAutoPlay={1}
              />
            </motion.li>
          )
        })}
      </ul>
    </Fragment>
  )
}

export default OpinionList
