import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'gatsby';
import { Parallax } from 'react-parallax';
import ReactMarkdown from "react-markdown"
import bannerImg from '../../../images/banner.jpg';
import './Banner.scss';
import { inViewOptions, contentItemStagger, contentItem } from '@Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import GetURL from '@Components/common/site/get-url';
import SearchMultipleArea from '../../SearchResults/SecondaryHeader/search-multiarea';
import AreaListBox from '../../SearchResults/SecondaryHeader/AreaList';
import _,{isEmpty, filter, find, isArray, isString, get, indexOf} from "lodash"
import { navigate } from "@reach/router"
import { Valuation_Page_Url } from "@Components/common/site/constants"
const Banner = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [type, setType] = useState("1");
	const toggling = (e) => {
		setIsOpen(!isOpen);
	}
	const submitHandler = (event) => {
		event.preventDefault();
	}
	const handleSelect = (key) => {
		if (key === "property-value"){
			navigate(Valuation_Page_Url.alias+"/");
		}
			

	}
	const onSelectChange = (e) => {
		setType(e.target.value)
	}
	const scrollDownHandler = () => {
		if (typeof window !== `undefined`) {
			window.scrollBy({
				top: 700,
				behavior: 'smooth'
			});
		}
	}
	const MotionCol = motion(Col)

	// Multi area search
	const [areaVal, setAreaVal] = useState('');
    const [areasList, setAreasList] = useState([]);
    const [areasArr, setAreasArr] = useState([]);

	//console.log("areaVal", areaVal, areasArr);

	
    
    const handleMultiAreaFun = (val) => {
        let areas = [];
        if (_.isEmpty(find(areasArr, val))) {
            areas = [...areasArr]
            areas.push(val)
            setAreasArr(areas)
        }
    }

    const removeArea = (area, clear=false) => {
        if (!clear) {
            let prevAreas = areasArr.filter( x => x.slug !== area)
            setAreasArr(prevAreas);
        } else {
            setAreasArr([]);
        }
    }

	const submitSearchResiSale = (e) => {
		var areaValue = (areaVal.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase());
		
		if(areasArr?.length > 0){
			areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
		} 

		var searchFilterResults = "";

		if(type === "1")
		{
			if(areaValue) {
				searchFilterResults = "/property/for-sale/in-" + areaValue + "/";
			} else {
				searchFilterResults = "/property/for-sale/in-ireland/";
			}
	
		}
		else if(type === "2"){
			if(areaValue) {
				searchFilterResults = "/new-homes-for-sale/in-" + areaValue + "/";
			} else {
				searchFilterResults = "/new-homes-for-sale/in-ireland/";
			}
		}
		else if(type === "3"){
			if(areaValue) {
				searchFilterResults = "/commercial-property/for-sale/in-" + areaValue + "/";
			} else {
				searchFilterResults = "/commercial-property/for-sale/in-ireland/";
			}
		}
		else if(type === "4"){
			if(areaValue) {
				searchFilterResults = "/auction-property/for-sale/in-" + areaValue + "/";
			} else {
				searchFilterResults = "/auction-property/for-sale/in-ireland/";
			}
		}

		navigate(searchFilterResults);

		window.scrollTo(0,0);
	}

	const submitSearchResiRent = (e) => {

		var areaValue = (areaVal.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase());
		
		if(areasArr?.length > 0){
			areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
		}

		
		var searchFilterResults = "";

		if(type === "1")
		{
			if(areaValue) {
				searchFilterResults = "/property/to-rent/in-" + areaValue + "/";
			} else {
				searchFilterResults = "/property/to-rent/in-ireland/";
			}
	
		}
		else if(type === "3"){
			if(areaValue) {
				searchFilterResults = "/commercial-property/to-rent/in-" + areaValue + "/";
			} else {
				searchFilterResults = "/commercial-property/to-rent/in-ireland/";
			}
		}
		
		
		navigate(searchFilterResults);

		window.scrollTo(0,0);
	}

	useEffect(()=>{
		const getId=document.getElementById('home-link-tags');
		const getChild=getId&&getId.firstChild;
		const element1=getChild?getChild.firstChild.href="javascript:void(0)":"";
		const element2=getChild?getChild.lastChild.href="javascript:void(0)":"";
	},[])

	


	return (
		<InView {...inViewOptions}>
			{({ ref, inView }) => (
				<motion.section
					className="banner gt"
					ref={ref}
					initial="hidden"
					animate={inView ? "visible" : "hidden"}
					variants={contentItemStagger}
				>
					{props.Banner_Image &&
						<Parallax className="banner-img" blur={10} bgImage={props.Banner_Image?.url} bgImageAlt={props.Banner_Image?.alternativeText} strength={500} />
					}
					<Container>
						<Row>
							<Col className="position-relative">
								{props?.Banner_Title && <motion.h1 key={0} custom={0} variants={contentItem}>{props?.Banner_Title}</motion.h1>}
								<div className={`${props.Pagename}-top-banner`}>
								{props?.Banner_Content && <motion.div className='banner-content' key={1} custom={1} variants={contentItem}><ReactMarkdown source={props?.Banner_Content} allowDangerousHtml /></motion.div>}
								{props?.Show_Form &&
									<motion.div className='banner-content' key={2} custom={2} variants={contentItem} id="home-link-tags">
										<Tabs onSelect={(e) =>handleSelect(e)} defaultActiveKey="property-search" className="banner-tab" >
											<Tab eventKey="property-search" title="find a property">
												<div className="form-wrap">
													<form onSubmit={submitHandler}>
														<div className="form-content">
															<div className="input-wrap">
																<select onClick={(e) => toggling(e)} className={`${isOpen ? 'is-open' : ''}`} onChange={(e) => onSelectChange(e)} value={type}>
																	<option value="1">Residential</option>
																	<option value="2">New Homes</option>
																	<option value="3">Commercial</option>
																	<option value="4">Auctions</option>
																</select>
																<div className='sep_line'></div>
																<div className="input-wrapper">
																	{/* <input type="text" className="form-control" placeholder="Start typing your property address" /> */}
																	<SearchMultipleArea 
																		areaVal={areaVal} 
																		placeholder={"Location or Address"} 
																		setAreaVal={setAreaVal} 
																		setAreasList={setAreasList} 
																		handleMultiAreaFun={handleMultiAreaFun} autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
																	/>
																</div>
															</div>
															<div className='d-md-none'>
															<div className="predictive-arelist-suggestion property-results p-0">
																<AreaListBox areas={areasArr} removeArea={removeArea} />
															</div>
															</div>
															<div className="btn-wrapper">
																{/* {type} */}
																<button className="btn" onClick={submitSearchResiSale}>For Sale</button>
																{type === "1" ? 
																<button className="btn" onClick={submitSearchResiRent}>To Rent</button>
																:type === "3" ?
																<button className="btn" onClick={submitSearchResiRent}>To Rent</button>
																: null
																}
															</div>
														</div>
													</form>
													<div className='d-none d-md-block'>
													<div className="predictive-arelist-suggestion property-results p-0">
														<AreaListBox areas={areasArr} removeArea={removeArea} />
													</div>
													</div>
												</div>
											</Tab>
											<Tab eventKey="property-value" title="value my property">
												
											</Tab>
										</Tabs>
									</motion.div>
								}
								</div>
								<motion.div className="d-none d-lg-flex" variants={contentItem}>
										{props.Cta_Links?.CTA_Btn_Label1 && props.Cta_Links?.HashLink1 &&
											<a onClick={props.executeScroll} className="btn">{props.Cta_Links?.CTA_Btn_Label1}</a>
										}
										{props.Cta_Links?.CTA_Btn_Label1 && props.Cta_Links?.CTA_Btn_Url1?.Alias &&
											<GetURL class="btn" label={props.Cta_Links?.CTA_Btn_Label1} alias={props.Cta_Links?.CTA_Btn_Url1?.Alias} />

										}
										{props.Cta_Links?.CTA_Btn_Label2 && props.Cta_Links?.CTA_Btn_Url2?.Alias &&
											<GetURL class="btn outline-btn" label={props.Cta_Links?.CTA_Btn_Label2} alias={props.Cta_Links?.CTA_Btn_Url2?.Alias} />
										}
									</motion.div>
								{props.Pagename === 'home' &&
									<div className="down-btn d-none d-xxl-block">
										<button type="button"
											onClick={scrollDownHandler}
										>
											<i className="icon-down"></i>
										</button>
									</div>
								}
							</Col>
						</Row>
					</Container>
				</motion.section>
			)}
		</InView>
	)
}

export default Banner;
