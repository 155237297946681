import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
// Image
import opinionImg1 from "../../../images/opinion-img-1.jpg"
import opinionImg2 from "../../../images/opinion-img-2.jpg"
import opinionImg3 from "../../../images/opinion-img-3.jpg"
import opinionImg4 from "../../../images/opinion-img-4.jpg"
// SCSS
import "./opinion.scss"
import OpinionList from "./OpinionList/OpinionList"
import OpinionListGrid from "./OpinionList/OpinionListGrid"
import ReactMarkdown from "react-markdown"
import ScrollAnimation from "react-animate-on-scroll"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import PlayVideo from "../../../Components/PlayVideo/PlayVideo"
import { NEWS_PAGE_URL } from "@Components/common/site/constants"
import NoImage from "../../../images/no-image.png"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const Opinion = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        blogCategories {
          Category_Name
        }
        blogs(
          sort: "Date:DESC"
          publicationState: LIVE
          where: { Publish: true }
        ) {
          id
          imagetransforms
          blog_categories {
            Category_Name
          }
          URL
          External_Blog_Url
          Video_Url
          Title
          Publish
          Meta_Title
          Meta_Description
          Date
          Banner_Content
          Tail_Image {
            alternativeText
            url
          }
          Add_Content {
            Content
            Add_Image {
              Caption
              Image {
                alternativeText
                url
              }
            }
          }
        }
      }
    }
  `)

  const [news, setNews] = useState(data.glstrapi?.blogs)
  const [filter, setFilter] = useState("All")
  const clickFilter = e => {
    setFilter(e)
    var arr = []
    data.glstrapi?.blogs
      .filter(list =>
        list.blog_categories.map(c => c.Category_Name).includes(e)
      )
      .map((item, index) => {
        arr.push(item)
      })
    setNews(e === "All" ? data.glstrapi?.blogs : arr)
    // loopWithSlice(0, postsPerPage);
  }
  var names = data.glstrapi?.blogCategories
  const [isPlay, setIsPlay] = useState(false)

  const playVideoHandler = () => {
    setIsPlay(true)
  }

  const postsPerPage = 6
  let arrayForHoldingPosts = []
  // const [] = useState(0);
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(6)
  const loopWithSlice = (start, end) => {
    const slicedPosts = news?.slice(0, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  const handleShowMorePosts = () => {
    loopWithSlice(
      postsToShow?.length > 17 ? next : 17,
      postsToShow?.length > 17 ? next + postsPerPage : 17 + postsPerPage
    )
    setNext(postsToShow?.length > 17 ? next + postsPerPage : 17 + postsPerPage)
  }

  useEffect(() => {
    loopWithSlice(0, 17)
    setNext(6)
  }, [filter])

  const ImageRender = ({ alt, image_url, node, post_item, imagesize }) => {
    let processedImages = JSON.stringify({})
    if (post_item.imagetransforms?.Tail_Image_Transforms) {
      processedImages = post_item.imagetransforms.Tail_Image_Transforms
    }

    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename={imagesize}
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }

  //   let processedImages1 = JSON.stringify({});
  //   if (news[0]?.imagetransforms?.Image_Transforms) {
  // 	processedImages1 = news[0]?.imagetransforms.Image_Transforms;
  //   }

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className={props.isNewsPage ? "opinion newspage" : "opinion normal"}
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col sm={props.isNewsPage ? 6 : 12}>
                {props?.Title && (
                  <motion.div variants={fadeInFromLeft}>
                    <ReactMarkdown source={props?.Title} allowDangerousHtml />
                  </motion.div>
                )}
                {props.isNewsPage && props.Content && (
                  <motion.div variants={fadeInFromLeft}>
                    <ReactMarkdown source={props?.Content} allowDangerousHtml />
                  </motion.div>
                )}
              </Col>

              {props.isNewsPage && (
                <Col sm={12}>
                  <motion.div
                    className="fiter-news-categories"
                    variants={fadeInFromLeft}
                  >
                    <ul>
                      <li
                        onClick={() => clickFilter("All")}
                        className={
                          filter === "All"
                            ? "all-news active-category list-tab"
                            : "all-news list-tab"
                        }
                      >
                        All Insights
                      </li>
                      {names.map(list => (
                        <li
                          onClick={() => clickFilter(list.Category_Name)}
                          className={
                            filter === list.Category_Name
                              ? "active-category list-tab"
                              : "list-tab"
                          }
                        >
                          {list.Category_Name}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                </Col>
              )}
              {props.isNewsPage ? (
                <Col sm={12}>
                  {postsToShow && postsToShow.length > 0 ? (
                    <>
                      <div className="content-wrapper">
                        <motion.div
                          key={0}
                          custom={0}
                          variants={fadeInFromLeft}
                          className="top-block"
                        >
                          <div className="img-wrapper">
                            <div className="flag d-none d-md-block">
                              {postsToShow[0]?.blog_categories?.map(
                                (list, index) => (
                                  <>
                                    {list.Category_Name}
                                    {index ===
                                    postsToShow[0]?.blog_categories?.length - 1
                                      ? ""
                                      : ", "}
                                  </>
                                )
                              )}
                            </div>
                            {postsToShow[0]?.Video_Url && (
                              <div className="btn-wrapper">
                                <button
                                  type="button"
                                  onClick={playVideoHandler}
                                >
                                  <i className="icon-play"></i>
                                </button>
                              </div>
                            )}
                            
                            {postsToShow[0]?.External_Blog_Url?
                            (
                              postsToShow[0]?.External_Blog_Url.startsWith("https")?
                            <a
                            href={postsToShow[0]?.External_Blog_Url}
                              className="img-zoom"
                            >
                              <picture>
                                {postsToShow[0]?.Tail_Image?.url ? (
                                  <ImageRender
                                    imagesize="blog.Tail_Image.bigtail"
                                    alt={
                                      postsToShow[0]?.Tail_Image
                                        ?.alternativeText
                                        ? postsToShow[0].Tail_Image
                                            .alternativeText
                                        : postsToShow[0].Title +
                                          " - DNG Estate Agents"
                                    }
                                    post_item={postsToShow[0]}
                                    image_url={postsToShow[0]?.Tail_Image?.url}
                                    node={postsToShow[0]}
                                  />
                                ) : (
                                  <img
                                    src={NoImage}
                                    alt={
                                      postsToShow[0]?.Tail_Image
                                        ?.alternativeText
                                    }
                                  />
                                )}
                              </picture>
                            </a>
                            :
                            <Link
                            to={postsToShow[0]?.External_Blog_Url}
                              className="img-zoom"
                            >
                              <picture>
                                {postsToShow[0]?.Tail_Image?.url ? (
                                  <ImageRender
                                    imagesize="blog.Tail_Image.bigtail"
                                    alt={
                                      postsToShow[0]?.Tail_Image
                                        ?.alternativeText
                                        ? postsToShow[0].Tail_Image
                                            .alternativeText
                                        : postsToShow[0].Title +
                                          " - DNG Estate Agents"
                                    }
                                    post_item={postsToShow[0]}
                                    image_url={postsToShow[0]?.Tail_Image?.url}
                                    node={postsToShow[0]}
                                  />
                                ) : (
                                  <img
                                    src={NoImage}
                                    alt={
                                      postsToShow[0]?.Tail_Image
                                        ?.alternativeText
                                    }
                                  />
                                )}
                              </picture>
                            </Link>
                            )
                             :
                             ( 
                            <Link
                              to={postsToShow[0]?.URL + "/ "}
                              className="img-zoom"
                            >
                              <picture>
                                {postsToShow[0]?.Tail_Image?.url ? (
                                  <ImageRender
                                    imagesize="blog.Tail_Image.bigtail"
                                    alt={
                                      postsToShow[0]?.Tail_Image
                                        ?.alternativeText
                                        ? postsToShow[0].Tail_Image
                                            .alternativeText
                                        : postsToShow[0].Title +
                                          " - DNG Estate Agents"
                                    }
                                    post_item={postsToShow[0]}
                                    image_url={postsToShow[0]?.Tail_Image?.url}
                                    node={postsToShow[0]}
                                  />
                                ) : (
                                  <img
                                    src={NoImage}
                                    alt={
                                      postsToShow[0]?.Tail_Image
                                        ?.alternativeText
                                    }
                                  />
                                )}
                              </picture>
                            </Link>
                             )
                          }
                          </div>
                          <h4>
                            {postsToShow[0]?.External_Blog_Url?
                            (
                             postsToShow[0]?.External_Blog_Url.startsWith("https")?
                            <a href={postsToShow[0]?.External_Blog_Url}>
                              {postsToShow[0]?.Title}
                            </a>  
                            :
                            <Link to={postsToShow[0]?.External_Blog_Url}>
                              {postsToShow[0]?.Title}
                            </Link> 
                            )
                            : 
                          
                            <Link to={postsToShow[0]?.URL + "/ "}>
                              {postsToShow[0]?.Title}
                            </Link>
                            }
                          </h4>
                          <ReactMarkdown
                            source={postsToShow[0]?.Banner_Content}
                            allowDangerousHtml
                          />
                          <PlayVideo
                            isOpen={isPlay}
                            stopPlay={setIsPlay}
                            videoId={postsToShow[0]?.Video_Url}
                            isAutoPlay={1}
                          />
                        </motion.div>
                        <div className="bottom-block">
                          <OpinionList
                            filter={filter}
                            news={postsToShow?.slice(1, 5)}
                          />
                        </div>
                      </div>

                      <OpinionListGrid
                        executeScrolljoin={props.executeScrolljoin}
                        news={postsToShow?.slice(5, postsToShow?.length)}
                      />
                    </>
                  ) : (
                    <div>No News found</div>
                  )}
                </Col>
              ) : (
                <Col sm={12}>
                  <div className="content-wrapper">
                    <motion.div
                      key={0}
                      custom={0}
                      variants={fadeInFromLeft}
                      className="top-block"
                    >
                      <div className="img-wrapper">
                        <div className="flag d-none d-md-block">
                          {news[0]?.blog_categories?.map((list, index) => (
                            <>
                              {list.Category_Name}
                              {index === news[0]?.blog_categories?.length - 1
                                ? ""
                                : ", "}
                            </>
                          ))}
                        </div>
                        {news[0]?.Video_Url && (
                          <div className="btn-wrapper">
                            <button type="button" onClick={playVideoHandler}>
                              <i className="icon-play"></i>
                            </button>
                          </div>
                        )}

                        {news[0]?.External_Blog_Url?
                        (
                          news[0]?.External_Blog_Url.startsWith("https")?
                        <a
                          href={news[0]?.External_Blog_Url}
                          className="img-zoom"
                        >
                          <picture>
                            {news[0]?.Tail_Image?.url ? (
                              <ImageRender
                                imagesize="blog.Tail_Image.bigtail"
                                alt={
                                  news[0]?.Tail_Image?.alternativeText
                                    ? news[0]?.Tail_Image?.alternativeText +
                                      " - DNG Estate Agents"
                                    : "Property News - DNG Estate Agents"
                                }
                                post_item={news[0]}
                                image_url={news[0]?.Tail_Image?.url}
                                node={news[0]}
                              />
                            ) : (
                              <img
                                src={NoImage}
                                alt={news[0]?.Tail_Image?.alternativeText}
                              />
                            )}
                           
                          </picture>
                        </a>
                        :
                        <Link
                        to={news[0]?.External_Blog_Url}
                        className="img-zoom"
                      >
                        <picture>
                          {news[0]?.Tail_Image?.url ? (
                            <ImageRender
                              imagesize="blog.Tail_Image.bigtail"
                              alt={
                                news[0]?.Tail_Image?.alternativeText
                                  ? news[0]?.Tail_Image?.alternativeText +
                                    " - DNG Estate Agents"
                                  : "Property News - DNG Estate Agents"
                              }
                              post_item={news[0]}
                              image_url={news[0]?.Tail_Image?.url}
                              node={news[0]}
                            />
                          ) : (
                            <img
                              src={NoImage}
                              alt={news[0]?.Tail_Image?.alternativeText}
                            />
                          )}
                         
                        </picture>
                      </Link>
                        )
                      :
                        <Link
                          to={NEWS_PAGE_URL.alias + "/" + news[0]?.URL + "/"}
                          className="img-zoom"
                        >
                          <picture>
                            {news[0]?.Tail_Image?.url ? (
                              <ImageRender
                                imagesize="blog.Tail_Image.bigtail"
                                alt={
                                  news[0]?.Tail_Image?.alternativeText
                                    ? news[0]?.Tail_Image?.alternativeText +
                                      " - DNG Estate Agents"
                                    : "Property News - DNG Estate Agents"
                                }
                                post_item={news[0]}
                                image_url={news[0]?.Tail_Image?.url}
                                node={news[0]}
                              />
                            ) : (
                              <img
                                src={NoImage}
                                alt={news[0]?.Tail_Image?.alternativeText}
                              />
                            )}
                           
                          </picture>
                        </Link>
                      }
                      </div>
                      <h4>
                        {news[0]?.External_Blog_Url?
                        (
                          news[0]?.External_Blog_Url.startsWith("https")?
                            <a href={news[0]?.External_Blog_Url}>
                              {news[0]?.Title}
                            </a> 
                            :
                            <Link to={news[0]?.External_Blog_Url}>
                            {news[0]?.Title}
                          </Link> 
                        )
                            :
                        <Link
                          to={NEWS_PAGE_URL.alias + "/" + news[0]?.URL + "/"}
                        >
                          {news[0]?.Title}
                        </Link>
                        }
                      </h4>
                      <ReactMarkdown
                        source={news[0]?.Banner_Content}
                        allowDangerousHtml
                      />
                      <PlayVideo
                        isOpen={isPlay}
                        stopPlay={setIsPlay}
                        videoId={news[0]?.Video_Url}
                        isAutoPlay={1}
                      />
                    </motion.div>
                    <div className="bottom-block">
                      <OpinionList filter={filter} news={news?.slice(1, 5)} />
                    </div>
                  </div>
                </Col>
              )}
              {props.isNewsPage && postsToShow?.length !== news?.length && (
                <Container>
                  <Row>
                    <Col md="12">
                      <div className="review-load">
                        <button
                          className="btn-pagination btn btn-primary"
                          onClick={handleShowMorePosts}
                        >
                          Load more
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </Row>
          </Container>
        </motion.section>
      )}
    </InView>
  )
}

export default Opinion
