import React, { useState } from "react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import GetURL from "@Components/common/site/get-url"
import Accordion from "react-bootstrap/Accordion"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
  fadeInFromTop,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import Modal from "react-bootstrap/Modal"
import TeamForm from "@Components/forms/teamcontact"
import NoImage from "../../../../images/no-image.png"
const aboutData = [
  {
    id: 1,
    heading: "people",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies convallis.",
    linkText: "Find a Person",
    link: "/",
  },
  {
    id: 2,
    heading: "about",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies convallis.",
    linkText: "our history",
    link: "/",
  },
  {
    id: 3,
    heading: "services",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies convallis.",
    linkText: "What we do",
    link: "/",
  },
  {
    id: 4,
    heading: "research",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies convallis.",
    linkText: "Learn More",
    link: "/",
  },
]

const AboutContent = props => {
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(null)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setIndex(event)
    setShow(true)
  }
  const [aboutDetails, setAboutDetails] = useState(aboutData)
  const [activeId, setActiveId] = useState(null)
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null)
    } else {
      setActiveId(id)
    }
  }
  return props.Tail_Type === "Toggle" ? (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="office-property"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          {props?.Content && (
            <motion.div variants={contentItem}>
              <ReactMarkdown source={props?.Content} allowDangerousHtml />
            </motion.div>
          )}
          <div className="accordion-wrap">
            <Accordion defaultActiveKey={activeId}>
              <ul>
                {props.Tails?.map((item, index) => (
                  <motion.li
                    variants={delayItemsFromBottom}
                    custom={index}
                    key={index}
                  >
                    <Accordion.Toggle
                      onClick={() => toggleActive(index + 1)}
                      className={
                        activeId === index + 1
                          ? "accordion-header minus"
                          : "accordion-header"
                      }
                      eventKey={index + 1}
                    >
                      {item.Title}
                      <i className="icon-plus"></i>
                      <i className="icon-minus"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index + 1}>
                      <div className="accordion-content">
                        {item?.Content && (
                          <ReactMarkdown
                            source={item?.Content}
                            allowDangerousHtml
                          />
                        )}
                      </div>
                    </Accordion.Collapse>
                  </motion.li>
                ))}
              </ul>
            </Accordion>
          </div>
        </motion.div>
      )}
    </InView>
  ) : props.Tail_Type === "Cta" ? (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.ul
          className="about-content d-md-flex flex-wrap justify-content-between"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          {props.Tails?.map((aboutItem, index) => (
            <motion.li
              variants={delayItemsFromBottom}
              className="about-item"
              custom={index}
              key={index}
            >
              {aboutItem.Title && <h3>{aboutItem.Title}</h3>}
              {aboutItem?.Content && (
                <ReactMarkdown source={aboutItem?.Content} allowDangerousHtml />
              )}
              {aboutItem.Label && aboutItem.Url && (
                <GetURL label={aboutItem.Label} alias={aboutItem.Url?.Alias} />
              )}
            </motion.li>
          ))}
        </motion.ul>
      )}
    </InView>
  ) : (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="office-property"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          {props?.Content && (
            <motion.div variants={contentItem}>
              <ReactMarkdown source={props?.Content} allowDangerousHtml />
            </motion.div>
          )}
          {props?.Choose_Team && props?.Choose_Team?.length > 0 ? (
            <div className="contact-sidebar newhomes-contact-sidebar">
              <div className="dealer-info">
                <div className="w-100 d-md-flex justify-content-between">
                  {props?.Choose_Team?.map((item, i) => {
                    return (
                      <div className="dealer-wrap">
                        <div className="img-wrap">
                          <img
                            src={item.Image?.url ? item.Image?.url : NoImage}
                            alt={
                              item.Image?.alternativeText
                                ? item.Image?.alternativeText +
                                  " - DNG Estate Agents"
                                : item.Name + " - DNG Estate Agents"
                            }
                          />
                        </div>
                        <div className="dealer-right">
                          <span className="dealer-name">{item.Name}</span>
                          <span className="dealer-position">
                            {item.Designation}
                          </span>
                          <span className="dealer-contact">
                            <a href={"tel:" + item.Phone}>{item.Phone}</a> /{" "}
                            <a
                              href="javascript:;"
                              onClick={() => handleShow(i)}
                            >
                              Email
                            </a>
                            {/* <a href="mailto:abc@abc.abc">Email</a> */}
                          </span>
                        </div>
                        {i === index && (
                          <Modal
                            show={show}
                            centered={true}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                          >
                            <Modal.Header
                              closeButton
                              className="contact-close-btn"
                            >
                              <Modal.Title className="w-100">
                                Email to {item.Name}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="form">
                              <TeamForm
                                toemail={item.Email}
                                officeNumber={item.Phone}
                                teamMember={item.Name}
                              />
                            </Modal.Body>
                          </Modal>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </motion.div>
      )}
    </InView>
  )
}

export default AboutContent
